import React from "react";
import { Row, Col } from "reactstrap";
import styled, { css } from "react-emotion";
import Img from "gatsby-image";

const Wrapper = styled(Row)`
    padding: 60px 0;
`;

const ImageWrapperClassName = css`
    width: 64px;
    height: 64px;
`;

type Props = {
    sizes: any;
};
const Header: React.SFC<Props> = ({ sizes }) => (
    <Wrapper>
        <Col style={{height: 64}}>
            <Img sizes={sizes} style={{height: 70, width: 300}}/>
        </Col>
    </Wrapper>
);

export default Header;
