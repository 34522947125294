import React from "react";
import { Card, CardImg, CardTitle, CardSubtitle, Button, CardText, CardBody } from "reactstrap";

type Props = {
    title: string;
    subTitle: string;
    description: string;
}
const HighlightCard: React.SFC<Props> = ({
    title,
    subTitle,
    description,
}) => (
    <Card>
        <CardBody>
            <CardTitle>{title}</CardTitle>
            <CardSubtitle>{subTitle}</CardSubtitle>
            <CardText>{description}</CardText>
            <Button href="https://goo.gl/forms/FCoUix2ND9m4H8uB2" target="_blank">Learn More</Button>
        </CardBody>
    </Card>
);

export default HighlightCard;
