import React from "react";
import styled, { injectGlobal } from "react-emotion";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";
import { graphql } from "gatsby";
import 'bootstrap/dist/css/bootstrap.min.css';

import { Landing, FeatureCards, Header } from "../scenes";
const global = injectGlobal`
    body, html {
        padding: 0;
        margin: 0;
        color: #333;
    }
`
const Wrapper = styled("div")`
    background: linear-gradient(to bottom, #0e0c29 0%,#40156b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0e0c29', endColorstr='#40156b',GradientType=0 );
    min-height: 100vh;
`;

export const query = graphql`
  query FetchIndexData {
    mobile: file(relativePath: { eq: "mobile.png" }) {
      childImageSharp {
        sizes(maxWidth: 700) {
          ...GatsbyImageSharpSizes
        }
      }
	},
	logo: file(relativePath: { eq: "logo.png" }) {
		childImageSharp {
		  sizes(maxWidth: 400) {
			...GatsbyImageSharpSizes
		  }
		}
	  },
  }
`;

export default ({ data }: any) => (
	<Wrapper>
		<Helmet>
			<title>Two Brackets</title>
		</Helmet>
		<Container>
			<Header sizes={data.logo.childImageSharp.sizes}/>
			<Landing sizes={data.mobile.childImageSharp.sizes} />
			<FeatureCards/>
		</Container>
		{global}
	</Wrapper>
);