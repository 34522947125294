import React from "react";
import { Row, Col, Jumbotron, Button } from "reactstrap";
import Img from "gatsby-image";
import styled, { css } from "react-emotion";

const Wrapper = styled(Row)`
    color: #fff;
    & .jumbotron {
        background-color: transparent;
        color: #fff;
        margin-bottom: 0;

        hr {
            background-color: white;
        }
    }
`;

const OuterWrapperClassName = css`
    width: 100%;
`;

type Props = {
    sizes: any;
}
const Landing: React.SFC<Props> = ({ sizes }) => (
    <Wrapper>
        <Col xs="12" md="7" className="d-flex align-items-center">
            <Jumbotron>
                <h1 className="display">We build web and mobile apps.</h1>
                <p className="lead">Work with a network of experts to help build your next web or mobile application.</p>
                <hr className="my-2" />
                <p>Our developers specialize in delivering innovative, high performance, and impactful solutions to modern businesses.</p>
                <p className="lead">
                <Button color="primary btn-lg" href="https://goo.gl/forms/FCoUix2ND9m4H8uB2" target="_blank">Learn More</Button>
                </p>
            </Jumbotron>
        </Col>
        <Col xs="12" md="5" className="d-flex align-items-center">
            <Img sizes={sizes} outerWrapperClassName={OuterWrapperClassName}/>
        </Col>
    </Wrapper>
);

export default Landing;
