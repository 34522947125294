import React from "react";
import { Row, Col } from "reactstrap";
import HighlightCard from "./HighlightCard";
import styled from "react-emotion";

const Wrapper = styled(Row)`
    margin-top: 60px;
`;

const FeatureCards: React.SFC = () => (
    <Wrapper>
        <Col xs="12" md="6" lg="4" className="mb-4">
            <HighlightCard
                title="Web Applications"
                subTitle="NodeJS, ASP.NET Core, React, Azure, AWS, GCloud"
                description="Get the most out of the latest tech to develop incredible web experiences, including ecommerce."
            />
        </Col>
        <Col xs="12" md="6" lg="4" className="mb-4">
            <HighlightCard
                title="Mobile Applications"
                subTitle="React Native, Xamarin, Native iOS and Android"
                description="Our certified Xamarin developers can help you deliver a first class native mobile experience."
            />
        </Col>
        <Col xs="12" md="6" lg="4" className="mb-4">
            <HighlightCard
                title="Training"
                subTitle="Xamarin, TypeScript, React, Node, Native, and more"
                description="We'll help get your team up to speed on the latest technologies with hands on, in person, training."
            />
        </Col>
    </Wrapper>
);

export default FeatureCards;
